<template>
  <ToolsFormEccentricity
    :prop-bearing-width="bearingWidth"
    :prop-bearing-length="bearingLength"
    :prop-bearing-thickness="bearingThickness"
    :prop-twisting-a="twistingA"
    :prop-twisting-b="twistingB"
    :prop-twisting-with-surcharge="twistingWithSurcharge"
    :prop-type-contact-surface="typeContactSurface"
    :disable-inputs="disableInputs"
  />
</template>

<script>
import ToolsFormEccentricity from '../../components/tools/ToolsFormEccentricity.vue';

export default {
  components: {
    ToolsFormEccentricity,
  },
  data() {
    return {
      bearingWidth: this.$store.state.fixedBearingWidth,
      bearingLength: this.$store.state.fixedBearingLength,
      bearingThickness: this.$store.state.fixedBearingThickness,
      twistingA: this.$store.state.fixedBearingTwistingA,
      twistingB: this.$store.state.fixedBearingTwistingB,
      twistingWithSurcharge: this.$store.state.fixedBearingTwistingWithSurcharge,
      typeContactSurface: this.$store.state.fixedBearingTypeContactSurface,
      disableInputs: [
        'bearingWidth',
        'bearingLength',
        'bearingThickness',
        'twistingA',
        'twistingB',
        'withTwistSurcharge',
        'typeContactSurface',
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
